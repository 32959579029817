import $ from 'jquery';

/**
 * IntuitSolutions.net - Custom JS that fires on ALL pages
 */
export default function (context) {
    // $(document).ready(function(){
    //     if (!$('body').hasClass('validated')) {
    //         $("div[data-test-info-type='price']").html("Select Co-Op to view prices");
    //     };
    // });
    /*
     * toggle search bar open on mobile when click search icon
     */
    $('#toggle-mobileSearch').on('click', (event) => {
        event.preventDefault(); // prevent default link click action
        $('.header__main-search').slideToggle('fast'); // toggle the search bar open
        $('#search_query').focus(); // try to put cursor inside the search input field
    });

    /**
     * log context while developing
     */
    if (context.inDevelopment) {
        console.log('context:', context);
    }

    $('#shopLink').on('click', (event) => {
        event.preventDefault();
        if ($('#moreLink').hasClass('is-open')) {
            $('#moreLink').removeClass('is-open');
            $('#moreLink').attr('aria-hideen', true);
            $('#more-collapsible').removeClass('is-open');
            $('#more-collapsible').attr('aria-hidden', false);
        }
    });
    $('#moreLink').on('click', (event) => {
        event.preventDefault();
        if ($('#shopLink').hasClass('is-open')) {
            $('#shopLink').removeClass('is-open');
            $('#shopLink').attr('aria-hideen', true);
            $('#shop-collapsible').removeClass('is-open');
            $('#shop-collapsible').attr('aria-hidden', false);
        }
    });
    $('.heroCarousel-description').each(function() {
        if ($(this).is(':empty')) {
            $(this).hide();
        };
    });
}
