import $ from 'jquery';

export default function ($scope) {

    const tabTitle = $('div[data-its-tab-title]');
    const tabText = $('div[data-its-tab-text]');

    tabTitle.map((i, title) => {
        $('.tabs').append(`
        <li class="tab" role="presentation">
        <a class="tab-title" href="#itsTab-${i}" aria-controls="itsTab-${i}" role="tab" tabindex="0" aria-selected="false">${title.textContent}</a>
        </li>`);
    });
    
    tabText.map((i, text) => {
        $('.tabs-contents').append(`
        <section class="tab-content" id="itsTab-${i}" aria-hidden="true" role="tabpanel">
        ${text.innerHTML}
        </section>`);
    });

    $('.view-description').click(function(e) {
        e.preventDefault();
        $('#description-tab')[0].scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
        $('.tab-title')[0].click();
    });
}
