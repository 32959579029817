import Papa from 'papaparse';
import * as Cookies from "js-cookie";
import utils from '@bigcommerce/stencil-utils';

// show/hide login modal
export default class CustomLogin {
    constructor($scope) {
        this.$scope = $scope;

        this.bindEvents();
    }

    generateCoops() {
        Papa.parse('/content/coops.csv', {
            download: true,
            header: true,
            complete: (results) => {
                let coopArray = [];
                results.data.forEach((el, i) => {
                    if (el.coop !== '') {
                        coopArray.push(el.coop);
                    }
                    let listedCoop = el.coop.replace(/\s/g, '').toLowerCase();
                    let coop = '';
                    if (Cookies.get('valid-coop')) {
                        coop = Cookies.get('valid-coop').replace(/\s/g, '').toLowerCase();
                    } else if ($('.customer-group-name').length) {
                        coop = $('.customer-group-name').replace(/\s/g, '').toLowerCase();
                    }
                    if (coop === listedCoop) {
                        if (el.hiddencats !== undefined) {
                            let hiddenCatsArray = el.hiddencats.split(',');
                            hiddenCatsArray.forEach(cat => {
                                $(`.${cat}`).hide();
                                $('.navPages-item a, .header__collapsible-menu-item a').toArray().filter(text => $(text).text().toLowerCase().replace(/\s/g, '') === cat && $(text).parent('li').hide());
                            });
                        }
                        // populate contact-us page with co-op contact info
                        $('.coop-contact-info__title').html(el.coop);
                        $('.coop-contact-info__address1').html(el.address1);
                        $('.coop-contact-info__address2').html(el.address2);
                        $('.coop-contact-info__city').html(el.city);
                        $('.coop-contact-info__state').html(el.state);
                        $('.coop-contact-info__zip').html(el.zip);
                        $('.coop-contact-info__phone').html(el.phone);
                        $('.coop-contact-info__link').html(el.url);
                        $('.coop-contact-info__link').attr('href', 'http://' + el.url);

                        // checks product grid for visible products, displays contact us message if there are none
                        if ($('.productGrid').children(':visible').length === 0) {
                            $('.coopProductMessage').html(`<p>We're sorry, <span class="coopName">${el.coop}</span> is not offiering this product at this time.</p><p>Please <a href="/contact-us/" class="coopContactLink">contact us</a> with any questions.</p>`);
                            $('.coopProductMessage').show();
                            $('.productGrid').hide();
                            $('.sortDiv').hide();
                        }
                        if (el.promo === 'TRUE') {
                            $('body').addClass(`validPromo`);
                        };
                    };
                });

                if ($('body').hasClass('validated') && $('body').hasClass('validPromo')) {
                    $('.heroCarouselPromo').show();
                } else if ($('body').hasClass('validated') && !$('body').hasClass('validPromo')) {
                    $('.heroCarouselCoop').show();
                } else {
                    $('.heroCarousel').show();
                };

                coopArray.sort().forEach((el, i) => {
                    $('#co-op').append(`<option value="${el}">${el}</option`);
                });
            }
        });
    }

    showModal(event) {
        event.preventDefault();
        $('.login-modal').show();
        $('.login-modal-overlay').show();
        let loginForm = $(event.currentTarget).attr('login-modal');
        // show/hide the appropriate forms based on links clicked
        switch (loginForm) {
            case 'register':
                $('.login-modal form').hide();
                if (Cookies.get('valid-coop')) {
                    const validCoop = Cookies.get('valid-coop', validCoop);
                    const validZip = Cookies.get('valid-zipcode', validZip);
                    $('input[name="zipcode"]').val(validZip).attr('readonly', true);
                    $('input[name="coop"]').val(validCoop).attr('readonly', true);
                    $('#create-form').show();
                    console.log(Cookies.get('valid-coop'))
                } else {
                    $('#validate-form').show();
                }
                break;
            case 'login':
                $('.login-modal form').hide();
                $('#login-form').show();
                break;
            case 'create':
                $('.login-modal form').hide();
                $('#create-form').show();
                break;
        }
    }

    hideModal() {
        $('.login-modal').hide();
        $('.login-modal-overlay').hide();
    }

    login(event) {
        event.preventDefault();
        let formData = {
            'login_email': $('#login-username').val().trim(),
            'login_pass': $('#login-password').val().trim()
        };
        $.post('/login.php?action=check_login', formData, (data) => {
            // if get a response
            if ($(data).length) {
                // chek for logged in
                utils.api.getPage('/account.php', { template: 'custom/account-info' }, (err, response) => {
                    if (response.trim().length !== 0) {
                        window.location = "/"; // just reload page so that segmentation can happen again
                    } else {
                        $('.login-modal__message').html('Invalid email or password. Please try again.');
                        $('.login-modal__message').slideDown();
                    }
                });
            }
        });
    }
    validate(event) {
        event.preventDefault();
        // check to see if the zip code is the correct length and if a co-op is selected
        const zipCodeLength = $('#zip-code').val().length;
        const coopIndex = $('#co-op option:selected').index();
        if (zipCodeLength !== 5) {
            alert('Please enter a valid 5 digit zip code.')
        } else if (coopIndex === 0) {
            alert('Please select a co-op.');
        } else {
            // if form is submitted, hide the modal, set cookies, then compare to csv
            this.validateCustomer();
        }
    }

    validateCustomer() {
        let validate_form_json = {};
        $('#validate-form').serializeArray().forEach(item => validate_form_json[item.name.replace(/-/, '')] = item.value);
        validate_form_json.zipcode = parseInt(validate_form_json.zipcode);
        console.log(validate_form_json);
        $.ajax({
            type: 'POST',
            url: 'https://energywisemnstorecustomervalidation.azurewebsites.net:443/api/ValidateCustomerS/triggers/manual/invoke?api-version=2020-05-01-preview&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=G4X03aw0gi8C77zV1blxk6yONdg2nshosXGOZIN9wPA',
            contentType: 'application/json',
            dataType: 'json',
            processData: false,
            data: JSON.stringify(validate_form_json),
            success: (data) => {
                console.log('Submission was successful.', data);
                if (data.length > 0) {
                    // first result from php file is the co-op
                    const validCoop = data[0].coop;
                    const validZip = data[0].zipcode;
                    // set co-op cookie
                    Cookies.set('valid-coop', validCoop);
                    Cookies.set('valid-zipcode', validZip);
                    // Cookies.set('customer-zipcode', $('#zip-code').val());
                    window.location = '/?validated';
                } else if (data.length == 0) {
                    $('.login-modal__message').html('Sorry, there was no match found for that zip code and co-op. Please try again.');
                    $('.login-modal__message').slideDown();
                } else {
                    $('.login-modal__message').html('Sorry, we encountered an error. Please try again.');
                    $('.login-modal__message').slideDown();
                }
            },
            error(data) { // if it was a failure message
                console.log('ERROR: Submission was unsuccessful.');
                console.log(data);
                $('#validate-error-text').html("We're sorry, there appears to have been a problem.");
            }
        });
    }

    createCustomer(event) {
        event.preventDefault();
        let create_form_json = {};
        $('#create-form').serializeArray().forEach(item => create_form_json[item.name.replace(/-/, '')] = item.value);
        create_form_json.zipcode = parseInt(create_form_json.zipcode);
        console.log(create_form_json);
        $.ajax({
            type: 'POST',
            url: 'https://energywisemnstorecustomervalidation.azurewebsites.net:443/api/CreateCustomerS/triggers/manual/invoke?api-version=2020-05-01-preview&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=nz6vPUtRVyhGCB3Z1lGIX7ULTkGAD_lXavjeSF4_kRo',
            contentType: 'application/json',
            dataType: 'json',
            processData: false,
            data: JSON.stringify(create_form_json),
            success: (data) => {
                console.log("data ", data);
                console.log('Submission was successful.');
                var status = "error";
                if (data.data && data.data.id) {
                    this.hideModal();
                    let formData = {
                        'login_email': $('input[name="email"]').val().trim(),
                        'login_pass': $('input[name="password"]').val().trim()
                    };
                    $.post('/login.php?action=check_login', formData, (data) => {
                        // if get a response
                        if ($(data).length) {
                            // chek for logged in
                            utils.api.getPage('/account.php', { template: 'custom/account-info' }, (err, response) => {
                                if (response.trim().length !== 0) {
                                    window.location = '/'; // just reload page so that segmentation can happen again
                                }
                            });
                        }
                    });
                } else if (data.message) {
                    $('.login-modal__message').html(data.message);
                    $('.login-modal__message').slideDown();
                }
            },
            error(response) { // if it was a failure message
                console.log('ERROR: Submission was unsuccessful.');
                let data = JSON.parse(response.responseText)
                console.log(data);
                var error_message = "We're sorry, there appears to have been a problem.";
                if (data && data.errors) {
                    for (var error_key in data.errors) {
                        var server_error_message = data.errors[error_key];
                        if (server_error_message.match(/already in use/i)) {
                            error_message = "This e-mail address is already in use.";
                        }
                        if (server_error_message.match(/passwords must be/i)) {
                            error_message = server_error_message;
                        }
                    }
                }
                $('.login-modal__message').html(error_message);
                $('.login-modal__message').slideDown();
            }
        });
    }

    validated() {
        if (window.location.href.indexOf('validated') > -1) {
            const validCoop = Cookies.get('valid-coop', validCoop);
            const validZip = Cookies.get('valid-zipcode', validZip);
            $('.login-modal').show();
            $('.login-modal-overlay').show();
            $('#create-form').show();
            $('input[name="zipcode"]').val(validZip).attr('readonly', true);
            $('input[name="coop"]').val(validCoop).attr('readonly', true);
            this.bodyClasses();
            $('#validate-form').hide();
        }
    }

    bodyClasses() {
        if (Cookies.get('valid-coop')) {
            let bodyClass = Cookies.get('valid-coop').replace(/\s/g, '').toLowerCase();
            bodyClass = bodyClass.replace('inc.', '');
            console.log("bodyClass ", bodyClass);
            $('body').addClass(`validated ${bodyClass}`);
            $('.header__alt-logo-image').hide();
            $(`.${bodyClass}-img`).show();
        } else if ($('.customer-group-name').length) {
            const customerGroupName = $('.customer-group-name').text();
            Cookies.set('valid-coop', customerGroupName);
            let bodyClass = customerGroupName.replace(/\s/g, '').toLowerCase();
            bodyClass = bodyClass.replace(',inc.', '');
            $('body').addClass(`validated ${bodyClass}`);
            $('.header__alt-logo-image').hide();
            $(`.${bodyClass}-img`).show();
        }
    }

    loginPage() {
        $('#login-page-login-form').on('submit', (e) => {
            e.preventDefault();
            let formData = {
                'login_email': $('#login_email').val().trim(),
                'login_pass': $('#login_pass').val().trim()
            };
            $.post('/login.php?action=check_login', formData, (data) => {
                // if get a response
                if ($(data).length) {
                    // chek for logged in
                    utils.api.getPage('/account.php', { template: 'custom/account-info' }, (err, response) => {
                        if (response.trim().length !== 0) {
                            window.location = "/"; // just reload page so that segmentation can happen again
                        } else {
                            alert('Invalid email or password. Please try again.');
                        }
                    });
                }
            });
        })
    }

    clearCookies() {
        Cookies.remove('valid-coop');
        Cookies.remove('valid-zipcode');
    }

    bindEvents() {
        this.generateCoops();
        this.loginPage();
        this.validated();
        this.bodyClasses();
        $(document).on('click', '[data-requires-validation]', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            Cookies.set('add_to_cart_clicked', true);
            this.showModal(e);
        });

        $('.login-modal-overlay, .skip').on('click', () => {
            this.hideModal();
        });
        $('[login-modal]').on('click', () => {
            this.showModal(event);
        });
        $('#validate-form').on('submit', () => {
            this.validate(event);
        });
        $('#create-form').on('submit', () => {
            this.createCustomer(event);
        });
        $('#login-form').on('submit', () => {
            this.login(event);
        });
        $('.backBttn').on('click', () => {
            this.generateCoops();
            $('.login-modal form').hide();
            $('#create-form').hide();
            $('#validate-form').show();
        });
        $('.logout').on('click', () => {
            this.clearCookies();
        })
    }
}
